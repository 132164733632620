import { Component, OnInit, Input } from '@angular/core';
import { FormFieldBase } from '../form-field-base';
import { Transaction } from 'src/app/transactions/transaction';

@Component({
  selector: 'app-form-field-view',
  templateUrl: './form-field-view.component.html',
  styleUrls: ['./form-field-view.component.scss']
})
export class FormFieldViewComponent implements OnInit {
  @Input() field: FormFieldBase<any>;
  @Input() currentTransaction: Transaction;

  constructor() { }

  ngOnInit() {
  }

  getFieldValue(field: FormFieldBase<any>): string {
    if (this.currentTransaction) {
      return this.currentTransaction.getFieldValue(field);
    }
    return '(null)';
  }

}
