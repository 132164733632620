import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as ConfigActions from './config.actions';
import { switchMap, map, withLatestFrom, tap, flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fromApp from '../../store/app.reducer';
import * as CompanyActions from '../../company/store/company.actions';
import * as TransactionActions from '../../transactions/store/transactions.actions';
import * as ActivitiesActions from '../../activities/store/activities.actions';
import { Store, select } from '@ngrx/store';
import { TransactionFields } from 'src/app/transaction-fields/transaction-fields';
import { DropdownField } from 'src/app/transaction-fields/form-field-dropdown';
import { FormFieldBase } from 'src/app/transaction-fields/form-field-base';
import { NameField } from 'src/app/transaction-fields/form-field-name';
import { AddressField } from 'src/app/transaction-fields/form-field-address';
import { CurrencyField } from 'src/app/transaction-fields/form-field-currency';
import { DateField } from 'src/app/transaction-fields/form-field-date';
import { CheckboxField } from 'src/app/transaction-fields/form-field-checkbox';
import { TextboxField } from 'src/app/transaction-fields/form-field-textbox';
import { environment } from '../../../environments/environment';
import { URLField } from 'src/app/transaction-fields/form-field-url';
import { AgentField } from 'src/app/transaction-fields/form-field-agent';

@Injectable()
export class ConfigEffects {

  refreshAll = createEffect(() =>
    this.actions$.pipe(
    ofType(ConfigActions.refreshAll),
   flatMap(action => {
      // console.log('Start refreshAll:')
      return [
        ConfigActions.fetchTransactionFields({refreshAll: true}),
      ];
    }),
    ) //,{ dispatch: false }
  );

  fetchTransactionFields = createEffect(() =>
    this.actions$.pipe(
    ofType(ConfigActions.fetchTransactionFields),
    flatMap(fetchAction => {
      // console.log('fetchTransactionFields:');
      // console.log(fetchAction);
      return this.http.get<TransactionFields>(
        environment.awsApiUrlBase + '/fields',
      ).pipe(map(getret => {
        return {transactionFields: getret, refreshAll: fetchAction.refreshAll};
      }))
    }),
    flatMap(({transactionFields, refreshAll}) => {
      // console.log('got transactionFields / refreshAll:')
      // console.log(transactionFields)
      // console.log(refreshAll)

      const newTransactionFields = new TransactionFields();
      for (const fieldJSON of transactionFields['formFields']) {
        let fb: FormFieldBase<any>;
        switch (fieldJSON['type']) {
          case 'Dropdown': {
            const f = new DropdownField();
            f.options = fieldJSON['options'];
            fb = f;
            break;
          }
          case 'Agent': {
            const f = new AgentField();
            // f.options = [
            //   { key: 0, value: 'Agent 0' },
            //   { key: 1, value: 'Agent 1' },
            // ];
            fb = f;
            break;
          }
          case 'Name': {
            const f = new NameField();
            fb = f;
            break;
          }
          case 'Address': {
            const f = new AddressField();
            fb = f;
            break;
          }
          case 'Currency': {
            const f = new CurrencyField();
            fb = f;
            break;
          }
          case 'Date': {
            const f = new DateField();
            fb = f;
            break;
          }
          case 'Checkbox': {
            const f = new CheckboxField();
            fb = f;
            break;
          }
          case 'Typebox': {
            const f = new TextboxField();
            fb = f;
            break;
          }
          case 'URL': {
            const f = new URLField();
            fb = f;
            break;
          }
          default: {
            const f = new TextboxField();
            fb = f;
            break;
          }
        }
        fb.key = fieldJSON['key'];
        fb.label = fieldJSON['label'];
        fb.width = fieldJSON['width'];
        fb.required = fieldJSON['required'];
        newTransactionFields.formFields.push(fb);
      }
      // console.log('newTransactionFields:');
      // console.log(newTransactionFields);
      const nextActions = []
      nextActions.push(ConfigActions.setTransactionFields({transactionFields: newTransactionFields}));
      nextActions.push(ConfigActions.loadingDone({loadingType: 'fields'}));
      if (refreshAll) {
        nextActions.push(CompanyActions.fetchStats());
        nextActions.push(TransactionActions.fetchTransactions());
        nextActions.push(TransactionActions.fetchPayees());
        nextActions.push(ActivitiesActions.fetchActivities());
      }
      return nextActions;
    }),
    ) //,{ dispatch: false }
  );

  constructor (private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>) {}
}
