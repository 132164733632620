import { FormFieldBase } from './form-field-base';

export class NameField extends FormFieldBase<{firstName: string, lastName: string}> {
  controlType = 'name';

  constructor() {
    super();
    // this.type = options['type'] || '';
    this.value = { firstName: null, lastName: null};
    this.subKeys = ['firstName','lastName'];
    this.fieldType = 'Name';
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    return valueObj.firstName + ' ' + valueObj.lastName;
  }

  sortCompare(valueA, valueB, rowA, rowB, sortDirection) {

    if (valueA['lastName'] === valueB['lastName']) {
      const idAgtidB = +rowA.id > +rowB.id;
      const ret: number = ((sortDirection === 'desc') ? 1 : -1) * (idAgtidB ? 1 : -1);
      // console.log('valA=' + valueA + ' valueB=' + valueB + ' idA=' + rowA.id + ' idB=' + rowB.id + ' sd=' + sortDirection + ' ret:' + ret)
      return ret;
    } else if (valueA['lastName'] < valueB['lastName']) {
      return -1;
    } else {
      return 1;
    }
  }

}
