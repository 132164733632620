import { Action, createReducer, on } from '@ngrx/store';
import * as activitiesActions from './activities.actions';
import * as cloneDeep from 'lodash/cloneDeep';
import { Activity } from '../activity';

export interface ActivityInt {
  companyId        : string;
  teamId           : string;
  agentId          : string;
  date             : string;
  minutes          ?: number;
  dials            ?: number;
  contacts         ?: number;
  nurtures         ?: number;
  listingApptsSet  ?: number;
  listingApptsHeld ?: number;
  listingsSigned   ?: number;
  buyerApptsSet    ?: number;
  buyerApptsHeld   ?: number;
  buyersSigned     ?: number;
  offersWritten    ?: number;
  showings         ?: number;
  openHousesHeld   ?: number;
  leadSource       ?: string;
  pondDials        ?: number;
  leadsFromPond    ?: number;
  notes            ?: string;
  pondMinutes      ?: number;
}

// NGRX Store State:
export interface State {
  currentAgentId: string,
  activities: Activity[];
}

const initialState: State = {
  currentAgentId: '0',
  activities: [],
};

export function activitiesReducer(activitiesState: State | undefined, activitiesAction: Action) {
  return createReducer(
    initialState,
    on(activitiesActions.setActivities, (state, action) =>
    {
      const newActivities = cloneDeep(action.activities);
      // console.log(newActivities);
      return {
        ...state,
        activities: newActivities,
      };
    }),
    on(activitiesActions.setCurrentAgentId, (state, action) =>
    {
      return {
        ...state,
        currentAgentId: action.id,
      };
    }),
  )(activitiesState, activitiesAction);
}
