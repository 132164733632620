import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TransactionFieldsModule } from './transaction-fields/transaction-fields.module';
import * as fromApp from './store/app.reducer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { environment } from 'src/environments/environment';
import { AuthEffects } from './auth/store/auth.effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TransactionsEffects } from './transactions/store/transactions.effects';
import { SharedModule } from './shared/shared.module';
import { ConfigEffects } from './config/store/config.effects';
import { CompanyEffects } from './company/store/company.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { ActivitiesEffects } from './activities/store/activities.effects';


@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
   ],
   imports: [
      TransactionFieldsModule,
      SharedModule,
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      FontAwesomeModule,
      StoreModule.forRoot(fromApp.appReducer),
      EffectsModule.forRoot([
        AuthEffects,
        TransactionsEffects,
        ConfigEffects,
        CompanyEffects,
        ActivitiesEffects]),
      StoreDevtoolsModule.instrument({logOnly: environment.production}),
      StoreRouterConnectingModule.forRoot(),
    ],
   providers: [
     AmplifyService,
     {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule {
  // constructor(private readonly router: Router,) {router.events.subscribe(console.log) }
  // static injector: Injector;
  // constructor(injector: Injector) {
  //     AppModule.injector = injector;
  // }
 }
