import { Action, createReducer, on } from '@ngrx/store';
import * as configActions from './config.actions';
import { FormFieldBase } from 'src/app/transaction-fields/form-field-base';
import { DropdownField } from 'src/app/transaction-fields/form-field-dropdown';
import { TextboxField } from 'src/app/transaction-fields/form-field-textbox';
import { NameField } from 'src/app/transaction-fields/form-field-name';
import { TransactionFields } from 'src/app/transaction-fields/transaction-fields';
import * as cloneDeep from 'lodash/cloneDeep';

export interface State {
  transactionFields: TransactionFields;
  loadingActive: {
    fields: boolean;
    transactions: boolean;
    company: boolean;
    payees: boolean;
    activities: boolean;
  }
};
const initialState: State = {
  transactionFields: new TransactionFields(),
  loadingActive: {
    fields: false,
    transactions: false,
    company: false,
    payees: false,
    activities: false,
  }
};

export function configReducer(configState: State | undefined, configAction: Action) {
  return createReducer(
    initialState,
    on(configActions.addTransactionField, (state, action) => {
      let newTransactionFields = state.transactionFields;
      newTransactionFields.formFields.push(action.transactionField);
      return ({
        ...state,
        transactionFields: newTransactionFields,
      })
    }),
    on(configActions.setTransactionFields, (state, action) => {
      const newTransactionFields = cloneDeep(action.transactionFields);
      return {
        ...state,
        transactionFields: newTransactionFields,
        // loadingActive: {
        //   fields: false,
        //   transactions: state.loadingActive.transactions,
        //   dashboard: state.loadingActive.dashboard,
        // }
      };
    }),
    on(configActions.loadingDone, (state, action) => {
      const newLoadingActive = cloneDeep(state.loadingActive);
      newLoadingActive[action.loadingType] = false;
      return {
        ...state,
        loadingActive: newLoadingActive,
        }
    }),
    on(configActions.refreshAll, (state) => {
      return {
        ...state,
        loadingActive: {
          fields: true,
          transactions: true,
          company: true,
          payees: true,
          activities: true,
        },
      };
    })
    // on(configActions.reverseTransactionFields, (state) =>
    //   ({
    //     ...state,
    //     transactionFields: [...state.transactionFields.formFields.reverse()],
    //   })
    // )
  )(configState, configAction);
}
