import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../user';
import * as AuthActions from './auth.actions';

export interface State { user: User; }
const initialState: State = { user: null };

export function authReducer(authState: State | undefined, authAction: Action) {
  return createReducer(
    initialState,
    on(AuthActions.userLogin, (state, action) =>
      ({ ...state,
        authError: null,
        loading: false,
        user: new User(
          action.email,
          action.cognitoUser)})),
    on(AuthActions.userLogout, state =>
      ({...state, user: null })),
  )(authState, authAction);
}

