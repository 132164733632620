import { FormFieldBase } from './form-field-base';

export class CurrencyField extends FormFieldBase<number> {
  controlType = 'currency';

  constructor() {
    super();
    this.fieldType = 'Currency';
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    // return '$' + valueObj;
    // return '$' + this.decimalPipe.transform(valueObj, '5.2-2');
    return '$' + this.decimalPipe.transform(valueObj, '1.0-0');
  }

  fromViewString(viewString: string): number {
    return +viewString.replace(/[^\d.]/g, '');
  }

}
