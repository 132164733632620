import { TransactionCommissionLine, TransactionCommissionLineOutputType } from './transaction-commission-line';

export class TransactionCommission {
  public lineItems: TransactionCommissionLine[] = [];

  constructor () {}

  addNewLine(newLine: TransactionCommissionLine, sourceNodeIndex: number, sourceNodeFromPrimary: boolean): number {
    newLine.inputNode = sourceNodeIndex;
    newLine.inputNodeFromPrimary = sourceNodeFromPrimary;
    this.lineItems.push(newLine);
    if (sourceNodeIndex !== -1) {
      if (sourceNodeFromPrimary) {
        this.lineItems[sourceNodeIndex].primaryOutputNodeIndex = this.lineItems.length - 1;
      } else {
        this.lineItems[sourceNodeIndex].secondaryOutputNodeIndex = this.lineItems.length - 1;
      }
    }
    return this.lineItems.length - 1;
  }

  addSplit(lineIndex: number, primary: boolean): number {
    let insertPos = lineIndex + 1;
    let newLine = new TransactionCommissionLine();
    newLine.description = 'Manually Added Split';
    newLine.isFixedAmount = false;
    newLine.amount = 5.0;
    newLine.inputNode = lineIndex;
    newLine.inputNodeFromPrimary = primary;
    newLine.numberOfOutputs = 2;
    newLine.primaryOutputType = TransactionCommissionLineOutputType.PAYMENT;
    newLine.primaryOutputPayeeId = '0';
    newLine.secondaryOutputType = TransactionCommissionLineOutputType.LINE_ITEM;

      // false,
      // 5.0,
      // lineIndex,
      // primary,
      // 2,
      // TransactionCommissionLineOutputType.PAYMENT,
      // 'New Agent',
      // null,
      // TransactionCommissionLineOutputType.LINE_ITEM,
      // null,
      // null);
    // newLine.inputNode = lineIndex;
    // newLine.inputNodeFromPrimary = primary;

    let fixSrcIndex: number = null;
    let fixSrcTypePrimary: boolean;
    if (primary) {
      if (this.lineItems[lineIndex].primaryOutputType === TransactionCommissionLineOutputType.LINE_ITEM) {
        fixSrcIndex = this.lineItems[lineIndex].primaryOutputNodeIndex;
        if (fixSrcIndex >= insertPos) {
          fixSrcIndex++;
        }
        fixSrcTypePrimary = true;
      }
    } else {
      if (this.lineItems[lineIndex].secondaryOutputType === TransactionCommissionLineOutputType.LINE_ITEM) {
        fixSrcIndex = this.lineItems[lineIndex].secondaryOutputNodeIndex;
        fixSrcTypePrimary = false;
        if (fixSrcIndex >= insertPos) {
          fixSrcIndex++;
        }
      }
    }

    for (let i = 0; i < this.lineItems.length ; i++) {
      if (this.lineItems[i].inputNode >= insertPos) {
        this.lineItems[i].inputNode++;
      }
      if (this.lineItems[i].primaryOutputType == TransactionCommissionLineOutputType.LINE_ITEM) {
        if (this.lineItems[i].primaryOutputNodeIndex >= insertPos) {
          this.lineItems[i].primaryOutputNodeIndex++;
        }
      }
      if (this.lineItems[i].secondaryOutputType == TransactionCommissionLineOutputType.LINE_ITEM) {
        if (this.lineItems[i].secondaryOutputNodeIndex >= insertPos) {
          this.lineItems[i].secondaryOutputNodeIndex++;
        }
      }
    }
    if (primary) {
      newLine.secondaryOutputType = this.lineItems[lineIndex].primaryOutputType;
      newLine.secondaryOutputNodeIndex = this.lineItems[lineIndex].primaryOutputNodeIndex;
      newLine.secondaryOutputPayeeId = this.lineItems[lineIndex].primaryOutputPayeeId;
      this.lineItems[lineIndex].primaryOutputType = TransactionCommissionLineOutputType.LINE_ITEM;
      this.lineItems[lineIndex].primaryOutputNodeIndex = insertPos;
    } else {
      newLine.secondaryOutputType = this.lineItems[lineIndex].secondaryOutputType;
      newLine.secondaryOutputNodeIndex = this.lineItems[lineIndex].secondaryOutputNodeIndex;
      newLine.secondaryOutputPayeeId = this.lineItems[lineIndex].secondaryOutputPayeeId;
      this.lineItems[lineIndex].secondaryOutputType = TransactionCommissionLineOutputType.LINE_ITEM;
      this.lineItems[lineIndex].secondaryOutputNodeIndex = insertPos;
    }
    this.lineItems.splice(insertPos, 0, newLine);

    if (fixSrcIndex !== null) {
      if (fixSrcTypePrimary) {
        this.lineItems[fixSrcIndex].inputNode = insertPos;
        this.lineItems[fixSrcIndex].inputNodeFromPrimary = false;
      } else {
        this.lineItems[fixSrcIndex].inputNode = insertPos;
        this.lineItems[fixSrcIndex].inputNodeFromPrimary = false;
      }
    }
    return insertPos;
  }

  deleteSplit(lineIndex: number, primary: boolean): number {
    let deletePos: number = lineIndex;
    let srcLineNum = this.lineItems[lineIndex].inputNode;
    let newSrcType = primary ? this.lineItems[lineIndex].secondaryOutputType : this.lineItems[lineIndex].primaryOutputType;
    let newSrcPayeeId = primary ? this.lineItems[lineIndex].secondaryOutputPayeeId : this.lineItems[lineIndex].primaryOutputPayeeId;
    let newSrcIndex = primary ? this.lineItems[lineIndex].secondaryOutputNodeIndex : this.lineItems[lineIndex].primaryOutputNodeIndex;
    // console.log('dp:' + deletePos + ' sln:' + srcLineNum + ' nst:' + newSrcType + ' nsp:' + newSrcPayee + ' nsi:' + newSrcIndex + ' nsp:' + newSrcPayee + 'len:' + this.lineItems.length);
    if (this.lineItems[lineIndex].inputNodeFromPrimary) {
      this.lineItems[srcLineNum].primaryOutputType = newSrcType;
      this.lineItems[srcLineNum].primaryOutputPayeeId = newSrcPayeeId;
      this.lineItems[srcLineNum].primaryOutputNodeIndex = newSrcIndex;
    } else {
      this.lineItems[srcLineNum].secondaryOutputType = newSrcType;
      this.lineItems[srcLineNum].secondaryOutputPayeeId = newSrcPayeeId;
      this.lineItems[srcLineNum].secondaryOutputNodeIndex = newSrcIndex;
    }
    if (newSrcType === TransactionCommissionLineOutputType.LINE_ITEM) {
      this.lineItems[newSrcIndex].inputNode = srcLineNum;
      this.lineItems[newSrcIndex].inputNodeFromPrimary = this.lineItems[lineIndex].inputNodeFromPrimary;
    }
    for (let i = 0; i < this.lineItems.length ; i++) {
      if (this.lineItems[i].inputNode > deletePos) {
        this.lineItems[i].inputNode--;
      }
      if (this.lineItems[i].primaryOutputType == TransactionCommissionLineOutputType.LINE_ITEM) {
        if (this.lineItems[i].primaryOutputNodeIndex > deletePos) {
          this.lineItems[i].primaryOutputNodeIndex--;
        }
      }
      if (this.lineItems[i].secondaryOutputType == TransactionCommissionLineOutputType.LINE_ITEM) {
        if (this.lineItems[i].secondaryOutputNodeIndex > deletePos) {
          this.lineItems[i].secondaryOutputNodeIndex--;
        }
      }
    }
    this.lineItems.splice(deletePos, 1);
    return deletePos;
  }

  getGCI(price: number): number {
    if (this.lineItems && this.lineItems.length > 0) {
      return this.lineItems[0].getPrimaryAmount(price);
    } else {
      return -1;
    }
  }

  getPayments(): any {
    const payments = [];
    for (let i = 0; i < this.lineItems.length ; i++) {
      if (this.lineItems[i].primaryOutputType === TransactionCommissionLineOutputType.PAYMENT) {
        payments.push({'payeeId': this.lineItems[i].primaryOutputPayeeId, 'amount': this.lineItems[i].primaryOutputAmount})
      }
      if (this.lineItems[i].numberOfOutputs >= 2 &&
         this.lineItems[i].secondaryOutputType === TransactionCommissionLineOutputType.PAYMENT) {
          payments.push({'payeeId': this.lineItems[i].secondaryOutputPayeeId, 'amount': this.lineItems[i].secondaryOutputAmount})
      }
    }
    return payments;
  }

  toJSON() {
    let linesJSON = []
    for (let li of this.lineItems) {
      linesJSON.push(li.toJSON())
    }
    return {lineItems: linesJSON};
  }

  fromJSON(jsonData) {
    this.lineItems.splice(0,this.lineItems.length);
    for (let li of jsonData.lineItems) {
      // console.log('li:')
      // console.log(li)
      let newTransCommLine = new TransactionCommissionLine();
      newTransCommLine.fromJSON(li);
      // console.log('newTransCommLine')
      // console.log(newTransCommLine)
      this.lineItems.push(newTransCommLine);
    }
  }

  // calculateTree() {
  //   for (let i = 0 ; i < this.lineItems.length ; i++) {
  //     this.lineItems[i].clearCache();
  //   }
  // }

}
