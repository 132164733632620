/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a8d03ea6-e2fe-43c3-a604-efd94d109d1b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_eia4s4T3b",
    "aws_user_pools_web_client_id": "3u4k4thdeec8hid64ip4kmim7i",
    "oauth": {}
};


export default awsmobile;
