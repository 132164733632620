import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as ActivitiesActions from './activities.actions';
import * as ConfigActions from '../../config/store/config.actions';
import { switchMap, map, withLatestFrom, tap, flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fromApp from '../../store/app.reducer';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { ActivityInt } from './activities.reducer';
import { Activity } from '../activity';

@Injectable()
export class ActivitiesEffects {

  fetchActivities = createEffect(() =>
    this.actions$.pipe(
    // tap(action => {console.log(action)}),
    ofType(ActivitiesActions.fetchActivities),
    switchMap(fetchAction => {
      // console.log('Getting activities');
      const getret = this.http.get<ActivityInt[]>(
      // const getret = this.http.get(
          environment.awsApiUrlBase + '/activities',
      );
      // console.log('got:');
      // console.log(getret);
      return getret;
    }),
    flatMap(activities => {
      // console.log('got activities:')
      // console.log(activities)
      const newActivities: Activity[] = [];
      // const activityCnt = new Object();
      for (const a of activities) {
        const newActivity = new Activity();
        newActivity.fromJSON(a);
        newActivities.push(newActivity);
        // if (!(a.agentId in activityCnt)) {
        //   activityCnt[a.agentId] = 0;
        // }
        // activityCnt[a.agentId] += 1;
      }
      // for (const agentId of Object.keys(activityCnt)) {
      //   console.log('agentId:' + agentId + ' -> ' + activityCnt[agentId]);
      // }
      const nextActions = []
      let agentId = '0';
      if (activities.length > 0) {
        agentId = activities[0].agentId;
      }
      nextActions.push(ActivitiesActions.setCurrentAgentId({id: agentId}));
      nextActions.push(ActivitiesActions.setActivities({activities: newActivities}));
      nextActions.push(ConfigActions.loadingDone({loadingType: 'activities'}));
      return nextActions;
    }),
    )
  );

  constructor (private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>) {}
}
