import { FormFieldBase } from './form-field-base';

export class URLField extends FormFieldBase<string> {
  controlType = 'textbox';
  type: string;

  constructor() {
    super();
    // this.type = options['type'] || '';
    this.fieldType = 'URL';
  }
}
