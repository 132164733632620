import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './activities.reducer';

export const getActivitiesState = createFeatureSelector<State>('activities');

export const getActivities = createSelector(
  getActivitiesState,
  (state: State) => {
    return state.activities;
  }
);

export const getAgentActivities = createSelector(
  getActivitiesState,
  (state, props) => {
    // console.log('getAgentActivities for: '+ props.id)
    return state.activities.filter(a => String(a.agentId) === String(props.id));
  }
);

export const getTeamActivities = createSelector(
  getActivitiesState,
  (state, props) => {
    return state.activities.filter(a => String(a.teamId) === String(props.id));
  }
);

export const getCurrentAgentId = createSelector(
  getActivitiesState,
  (state) => {
    // console.log('getAgentActivities for: '+ props.id)
    return state.currentAgentId;
  }
);
