import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as TransactionsActions from './transactions.actions';
import * as ConfigActions from '../../config/store/config.actions';
import { switchMap, map, withLatestFrom, tap, flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Transaction } from '../transaction';
import { Injectable } from '@angular/core';
import * as fromApp from '../../store/app.reducer';
import { Store, select } from '@ngrx/store';
import { TransactionCommission } from '../transaction-commission';
import { TransactionCommissionLine } from '../transaction-commission-line';
import { getTransactions } from './transactions.selectors';
import { getTransactionFields } from 'src/app/config/store/config.selectors';
import { TransactionFields } from 'src/app/transaction-fields/transaction-fields';
import { environment } from '../../../environments/environment';
import { PayeeItem } from './transactions.reducer';

@Injectable()
export class TransactionsEffects {

  fetchTransactions = createEffect(() =>
    this.actions$.pipe(
    // tap(action => {console.log(action)}),
    ofType(TransactionsActions.fetchTransactions),
    switchMap(fetchAction => {
      // console.log('Getting transactions');
      const getret = this.http.get<Transaction[]>(
        environment.awsApiUrlBase + '/transactions',
      );
      // console.log('got:');
      // console.log(getret);
      return getret;
    }),
    flatMap(transactions => {
      // console.log('got transactions:')
      // console.log(transactions)
      let newTransactions: Transaction[] = [];
      for (const apiTrans of transactions) {

        let transactionFields: TransactionFields;
        this.store.pipe(select(getTransactionFields))
        .subscribe(
          subFields => {
            transactionFields = subFields;
          }
        );
        // console.log(transactionFields)

        let newTrans = new Transaction();
        // console.log(newTrans);
        newTrans.fromJSON(transactionFields,apiTrans);
        // console.log('fetchTransactions(): newTrans:');
        // console.log(newTrans);
        newTransactions.push(newTrans);
      }
      // console.log(newTransactions)
      // console.log(typeof newTransactions[0])
      const nextActions = []
      nextActions.push(TransactionsActions.setTransactions({transactions: newTransactions}));
      nextActions.push(ConfigActions.loadingDone({loadingType: 'transactions'}));
      return nextActions;
      // return TransactionsActions.setTransactions({transactions: newTransactions});

      // return newTransactions.map(transaction => {
      //   return {
      //     ...transaction,
      //     // ingredients: recipe.ingredients ? recipe.ingredients : [],
      //   };
      // });
    }),
    // map((transactions: Transaction[]) => {
    //   console.log(transactions)
    //   console.log(typeof transactions[0])
    //   return TransactionsActions.setTransactions({transactions});
    // })
    )
  );

  fetchPayees = createEffect(() =>
    this.actions$.pipe(
    // tap(action => {console.log(action)}),
    ofType(TransactionsActions.fetchPayees),
    switchMap(fetchAction => {
      // console.log('Getting transactions');
      const getret = this.http.get<any[]>(
        environment.awsApiUrlBase + '/payees',
      );
      // console.log('get /payees returned:');
      // console.log(getret);
      return getret;
    }),
    flatMap(payees => {
      // console.log('got payees:')
      // console.log(payees)
      // let newPayeesById = {};
      const newPayeeList: PayeeItem[] = [];
      for (const apiPayee of payees) {
        // console.log(apiPayee);
        const newPayee: PayeeItem = {
          id:            apiPayee.id,
          active:        apiPayee.active,
          companyId:     apiPayee.companyId,
          teamId:        apiPayee.teamId,
          type:          apiPayee.type,
          name:          apiPayee.name,
          companyDollar: apiPayee.companyDollar,
        };
        newPayeeList.push(newPayee);
        // console.log(newPayee);
      }
      // console.log(newTransactions)
      // console.log(typeof newTransactions[0])
      const nextActions = []
      nextActions.push(TransactionsActions.setPayees({payees: newPayeeList}));
      nextActions.push(ConfigActions.loadingDone({loadingType: 'payees'}));
      return nextActions;
    }),
    )
  );

  storeTransaction = createEffect(() =>
    this.actions$.pipe(
    ofType(TransactionsActions.storeTransaction),
    switchMap(storeAction => {
      // console.log(storeAction)

      let transactionFields: TransactionFields;
      this.store.pipe(select(getTransactionFields))
        .subscribe(
          subFields => {
            transactionFields = subFields;
          }
        );

      // console.log(storeAction.transaction.toJSON(transactionFields))
      const getret = this.http.patch(
        environment.awsApiUrlBase + '/transaction',
        storeAction.transaction.toJSON(transactionFields),
      );
      // console.log('got:');
      // console.log(getret);
      return getret;
    }),
    map(transaction => {
      // console.log(transaction)

      let transactionFields: TransactionFields;
      this.store.pipe(select(getTransactionFields))
      .subscribe(
        subFields => {
          transactionFields = subFields;
        }
      );

      let newTrans = new Transaction();
      newTrans.fromJSON(transactionFields,transaction);
      return TransactionsActions.updateTransaction({transaction: newTrans});
    }),
    ) //,{ dispatch: false } // <- this effect doesn't dispatch a new effect
  );

  addTransaction = createEffect(() =>
    this.actions$.pipe(
    ofType(TransactionsActions.addTransaction),
    switchMap(storeAction => {
      // console.log(storeAction)
      // console.log(storeAction.transaction.toJSON())

      let transactionFields: TransactionFields;
      this.store.pipe(select(getTransactionFields))
        .subscribe(
          subFields => {
            transactionFields = subFields;
          }
        );

      const getret = this.http.put(
        environment.awsApiUrlBase + '/transaction',
        storeAction.transaction.toJSON(transactionFields),
      );
      // console.log('got:');
      // console.log(getret);
      return getret;
    }),
    map(transaction => {
      // console.log(transaction)

      let transactionFields: TransactionFields;
      this.store.pipe(select(getTransactionFields))
      .subscribe(
        subFields => {
          transactionFields = subFields;
        }
      );

      let newTrans = new Transaction();
      newTrans.fromJSON(transactionFields,transaction);
      return TransactionsActions.updateTransaction({transaction: newTrans});
    }),
    ) //,{ dispatch: false } // <- this effect doesn't dispatch a new effect
  );

  constructor (private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>) {}
}
