import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as companyActions from './company.actions';
import * as cloneDeep from 'lodash/cloneDeep';

export interface State {
  stats: any;
};
const initialState: State = {
  stats: {},
};

export function companyReducer(companyState: State | undefined, companyAction: Action) {
  return createReducer(
    initialState,
    on(companyActions.setStats, (state, action) => {
      // console.log(action)
      const updatedStats = cloneDeep(action.stats);
      return {
        ...state,
        stats: updatedStats,
      };
    })
  )(companyState, companyAction);
}
