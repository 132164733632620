import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { FormFieldBase } from '../form-field-base';
import { Subscription, Observable } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TransactionsPayeesService } from 'src/app/transactions/transactions-payees.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-form-field-edit',
  templateUrl: './form-field-edit.component.html',
  styleUrls: ['./form-field-edit.component.scss']
})
export class FormFieldEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() field: FormFieldBase<any>;
  @Input() form: FormGroup;
  private formSub: Subscription;
  public search;
  // search = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map(term => term.length < 2 ? []
  //       : this.getAgentNamesOptions().filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  //   )

  // @ViewChild('agentSearchDropdown', { static: false }) agentSearchElementRef: NgbDropdown;
  get isValid(): boolean {
    if (this.field.subKeys.length > 0) {
      let valid = true;
      for (const sk of this.field.subKeys) {
        // console.log('key:' + this.field.key + '.' + sk);
        if (!this.form.controls[this.field.key + '.' + sk].valid) {
          valid = false;
        }
        return valid;
      }
    }
    // console.log(this.form);
    // console.log(this.field);
    return this.form.controls[this.field.key].valid;
  }

  fieldErrorMsg(): string {
    return this.field.label + ' is required';
  }

  constructor(public transactionsPayeeService: TransactionsPayeesService) { }

  ngOnInit() {
    // console.log('FormFieldEditComponent: onInit: ' + this.field.key)
    // if (this.field.controlType === 'agent') {
    //   this.search = (text$: Observable<string>) =>
    //   text$.pipe(
    //     debounceTime(200),
    //     distinctUntilChanged(),
    //     map(term => term.length < 2 ? []
    //       : this.getAgentNamesOptions().filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    //   )
    // }

    // if (this.field.controlType === 'agent') {
    //   console.log('create form sub')
    //   this.formSub = this.form.valueChanges
    //   // .debounceTime(200)
    //   // .distinctUntilChanged()
    //   .subscribe(val => {
    //       console.log(val);
    //       // console.log(this.agentSearchElementRef)
    //       // if (this.agentSearchElementRef !== undefined) {
    //         // this.agentSearchElementRef.nativeElement.closest('.dropdown').classList.add('show');
    //         // this.agentSearchElementRef.nativeElement.closest('.dropdown').querySelector(".dropdown-menu").classList.add('show');
    //         // this.agentSearchElementRef.open();
    //       // }
    //   });
    // }
  }

  ngAfterViewInit() {
    // console.log(this.agentSearchElementRef)
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  getAgentOptions(): {key: string, value: string}[] {
    return TransactionsPayeesService.instance.getDropdownOptions('agent', true);
  }

  getAgentNamesOptions(): string[] {
    const names: string[] = [];
    for (const a of TransactionsPayeesService.instance.getDropdownOptions('agent', true)) {
      names.push(a.value);
    }
    return names;
  }

  onChange() {
    console.log('onchange');
  }

  // onAgentSelect(agentId) {
  //   // console.log('got click: id=' + agentId);
  //   // if (true) {
  //   //   this.agentSearchElementRef.nativeElement.closest('.dropdown').classList.add('show');
  //   //   this.agentSearchElementRef.nativeElement.closest('.dropdown').querySelector(".dropdown-menu").classList.add('show');
  //   // } else {
  //   //   this.agentSearchElementRef.nativeElement.closest('.dropdown').classList.remove('show');
  //   //   this.agentSearchElementRef.nativeElement.closest('.dropdown').querySelector(".dropdown-menu").classList.remove('show');
  //   // }
  // }

}
