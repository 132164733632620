import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DropdownDirective } from './dropdown.directive';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  providers: [DatePipe],
  declarations: [
    DropdownDirective,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  exports: [
    DropdownDirective,
    CommonModule,
    LoadingComponent,
  ]
})
export class SharedModule { }
