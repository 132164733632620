import { FormFieldBase } from './form-field-base';

export class TextboxField extends FormFieldBase<string> {
  controlType = 'textbox';

  constructor() {
    super();
    // this.type = options['type'] || '';
    this.fieldType = 'Textbox';
  }
}
