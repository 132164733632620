import { DatePipe, DeprecatedDatePipe, DecimalPipe } from '@angular/common';
import { TransactionFields } from './transaction-fields';
// import { FormControlService } from './form-control.service';

export class FormFieldBase<T> {
  public datePipe: DatePipe;
  public decimalPipe: DecimalPipe;
  value: T;
  key: string;
  subKeys: string[] = [];
  label: string;
  width: number;
  required: boolean;
  fieldType: string;
  controlType: string;
  // options: {key: T, value: string}[];

  // constructor(
  //   options: {
  //     key?: string,
  //     label?: string,
  //     required?: boolean,
  //   } = {}) {
  //   this.key = options.key || '';
  //   this.label = options.label || '';
  //   this.required = !!options.required;
  //   this.datepipe = new DatePipe('en-US');
  // }

  constructor() {
    this.datePipe = new DatePipe('en-US');
    this.decimalPipe = new DecimalPipe('en-US');
  }

  initField(options: {
    key?: string,
    label?: string,
    required?: boolean,
  }) {
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
  }

  getOptions(): {key: string, value: string}[] {
    return [];
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    // if (valueObj instanceof Date) {
    //   return this.datePipe.transform(valueObj, 'MM/dd/yyyy');
    // }
    // if (typeof valueObj === 'number') {
    //   return '$' + valueObj;
    // }
    return String(valueObj);
  }

  fromViewString(viewString: string): any {
    return viewString;
  }

  foo(viewString: string): any {
    return viewString;
  }

  getFormValue(valueObj: any): any {
    return valueObj;
  }

  extractFormValue(field: FormFieldBase<any>, formValues: any): any {
    if (field.subKeys.length > 0) {
      let valObj = {};
      for (const sk of field.subKeys) {
        valObj[sk] = formValues[field.key + '.' + sk];
      }
      return valObj;
    }
    return formValues[field.key];
  }

  toJSON(valObj: T): any {
    return valObj;
  }

  fromJSON(jsonVal: any): T {
    return jsonVal;
  }

  sortCompare(valueA, valueB, rowA, rowB, sortDirection) {
    // console.log('sortCompare(base): ' + valueA + ':' + valueB + ' dir=' + sortDirection)
    // console.log(valueA)
    // console.log(valueB)
    // console.log(rowA)
    // console.log(rowB)
    // console.log(this)
    // console.log(typeof this)
    // console.log(this.foo('test'));
    // console.log(this.fromViewString('test')); //valueA.toString()))
    // console.log(this.fromViewString(valueB.toString()))

    if (valueA === valueB) {
      const idAgtidB = +rowA.id > +rowB.id;
      const ret: number = ((sortDirection === 'desc') ? 1 : -1) * (idAgtidB ? 1 : -1);
      // console.log('valA=' + valueA + ' valueB=' + valueB + ' idA=' + rowA.id + ' idB=' + rowB.id + ' sd=' + sortDirection + ' ret:' + ret)
      return ret;
    } else if (valueA < valueB) {
      return -1;
    } else {
      return 1;
    }
  }

}

