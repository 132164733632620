import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { FormFieldEditComponent } from './form-field-edit/form-field-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldViewComponent } from './form-field-view/form-field-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  providers: [DatePipe],
  declarations: [
    FormFieldEditComponent,
    FormFieldViewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    CommonModule,
    FormFieldEditComponent,
    FormFieldViewComponent,
  ]
})
export class TransactionFieldsModule { }
