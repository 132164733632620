import { FormFieldBase } from './form-field-base';

export class TransactionFields {
  formFields: FormFieldBase<any>[] = [];

  constructor() {}

  getFieldByKey(key: string) {
    for (const f of this.formFields) {
      if (f.key === key) {
        return f;
      }
    }
    return null;
  }
}
