import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as fromApp from '../store/app.reducer';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as AuthActions from '../auth/store/auth.actions';
import * as ConfigActions from '../config/store/config.actions';
import { getAuthUserEmail } from '../auth/store/auth.selectors';
import { getLoadingActive } from '../config/store/config.selectors';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { getCurrentAgentId } from '../activities/store/activities.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private currentAgentSub: Subscription;
  private userSub: Subscription;
  private loadingSub: Subscription;

  public currentAgentId;
  public loadingActive;
  userEmail: string = null;
  isAuthenticated = false;
  faSync = faSync;

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.currentAgentSub = this.store.pipe(select(getCurrentAgentId))
    .subscribe(
      id => {
        this.currentAgentId = id;
        // console.log('got currentAgentId:' + this.currentAgentId);
      }
    );

    this.userSub = this.store.pipe(select(getAuthUserEmail))
      .subscribe(
        userEmail => {
          // console.log(userEmail);
          this.isAuthenticated = userEmail !== null;
          this.userEmail = userEmail;
          // console.log('isAuth=' + this.isAuthenticated + ' userEmail=' + userEmail);
      }
    );

    this.loadingSub = this.store.pipe(select(getLoadingActive))
      .subscribe(
        loadingActive => {
          this.loadingActive = false;
          if (loadingActive.company || loadingActive.transactions || loadingActive.fields || loadingActive.activities) {
            this.loadingActive = true;
          }
          // console.log(loadingActive);
          // console.log(' -> ' + this.loadingActive);
      }
    );
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
  }

  onRefresh() {
    this.store.dispatch(ConfigActions.refreshAll());
  }

  onLogout() {
    this.store.dispatch(AuthActions.userLogout());
  }

}
