import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './auth.reducer';

export const getAuthState = createFeatureSelector<State>('auth');

export const getAuthUserEmail = createSelector(
  getAuthState,
  (state: State) => {
    if (state.user) {
      return state.user.email;
    } else {
      return null;
    }
  }
);

export const getAuthCognitoUser = createSelector(
  getAuthState,
  (state: State) => {
    if (state.user) {
      return state.user.cognitoUser;
    } else {
      return null;
    }
  }
);
