// import { DatePipe } from '@angular/common';

export class Activity {
  // public datepipe: DatePipe;

  public companyId        : string;
  public teamId           : string;
  public agentId          : string;
  public date             : Date;
  public minutes          : number;
  public dials            : number;
  public contacts         : number;
  public nurtures         : number;
  public listingApptsSet  : number;
  public listingApptsHeld : number;
  public listingsSigned   : number;
  public buyerApptsSet    : number;
  public buyerApptsHeld   : number;
  public buyersSigned     : number;
  public offersWritten    : number;
  public showings         : number;
  public openHousesHeld   : number;
  public leadSource       : string;
  public pondDials        : number;
  public leadsFromPond    : number;
  public notes            : string;
  public pondMinutes      : number;

  // private transactionFieldsService: TransactionFieldsService;

  constructor () {
    // this.datepipe = new DatePipe('en-US');
  }

  fromJSON(jsonData) {
    this.companyId        = jsonData.companyId       ;
    this.teamId           = jsonData.teamId          ;
    this.agentId          = jsonData.agentId         ;
    let newDate = null;
    const dates = jsonData.date.split('-');
    // console.log('dates: ' + dates.join(','));
    if (dates.length === 3) {
      newDate = new Date(dates[0],+dates[1]-1,dates[2]);
    }
    this.date             = newDate;
    // console.log('AC: json:' + jsonData.date + ' -> ' + newDate);
    this.minutes          = jsonData.minutes         ;
    this.dials            = jsonData.dials           ;
    this.contacts         = jsonData.contacts        ;
    this.nurtures         = jsonData.nurtures        ;
    this.listingApptsSet  = jsonData.listingApptsSet ;
    this.listingApptsHeld = jsonData.listingApptsHeld;
    this.listingsSigned   = jsonData.listingsSigned  ;
    this.buyerApptsSet    = jsonData.buyerApptsSet   ;
    this.buyerApptsHeld   = jsonData.buyerApptsHeld  ;
    this.buyersSigned     = jsonData.buyersSigned    ;
    this.offersWritten    = jsonData.offersWritten   ;
    this.showings         = jsonData.showings        ;
    this.openHousesHeld   = jsonData.openHousesHeld  ;
    this.leadSource       = jsonData.leadSource      ;
    this.pondDials        = jsonData.pondDials       ;
    this.leadsFromPond    = jsonData.leadsFromPond   ;
    this.notes            = jsonData.notes           ;
    this.pondMinutes      = jsonData.pondMinutes     ;
  }

}

