import { Component, OnInit, OnDestroy } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Store, select } from '@ngrx/store';
import { getLoadingActive } from 'src/app/config/store/config.selectors';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  faSync = faSync;
  public loadingActive = false;
  public messageText = 'Loading...';
  private loadingSub: Subscription;

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.loadingSub = this.store.pipe(select(getLoadingActive))
    .subscribe(
      loadingActive => {
        this.loadingActive = false;
        if (loadingActive.company || loadingActive.transactions || loadingActive.fields) {
          this.loadingActive = true;
        }
        // console.log(loadingActive);
        // console.log(' -> ' + this.loadingActive);
      }
    );
  }

  ngOnDestroy() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
  }

}
