import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './config.reducer';

export const getConfigState = createFeatureSelector<State>('config');

export const getTransactionFields = createSelector(
  getConfigState,
  (state: State) => state.transactionFields
);

export const getLoadingActive = createSelector(
  getConfigState,
  (state: State) => state.loadingActive
);

export const getTransactionFieldByKey = createSelector(
  getConfigState,
  (state, props) => {
    return state.transactionFields.find((field, index) => {
      return field.key === props.key;
    })
  }
);
