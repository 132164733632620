import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import { getPayees } from './store/transactions.selectors';
import { PayeeItem } from './store/transactions.reducer';

@Injectable({
  providedIn: 'root'
})
export class TransactionsPayeesService {
  static instance: TransactionsPayeesService;
  private payees: PayeeItem[] = [];

  // constructor() {
  //   console.log('payees service constructor')
  // }

  constructor(private store: Store<fromApp.AppState>) {
    // console.log('payees service constructor')
    this.store.pipe(select(getPayees))
    .subscribe(
      (payees: PayeeItem[]) => {
        // console.log('payees service got ' + payees.length + ' payees')
        this.payees = payees;
        // console.log(payees)
      }
    );
    TransactionsPayeesService.instance = this;
  }

  getDropdownOptions(filterType: string = null, filterActive = null): {key: string, value: string}[] {
    const dropdownOptions: {key: string, value: string}[] = [];
    for (const p of this.payees) {
      if (filterType === null || p.type === filterType) {
        if (p.type !== 'agent' || filterActive === null || p.active === filterActive) {
          dropdownOptions.push({key: p.id.toString(), value: p.name});
        }
      }
    }
    return dropdownOptions;
  }

  getNameById(id: string): string {
    for (const p of this.payees) {
      // console.log('p.id="' + p.id + '" (' + typeof(p.id) + ') id="' + id + '" (' + typeof(id) + ')')
      if (String(p.id) === String(id)) {
        return p.name;
      }
    }
    return 'unknown:' + id;// + '/len=' + TransactionsPayeesService.payees.length;
  }

  getPayees(filterType: string = null, filterActive = null): {id: string, name: string}[] {
    const payeesRet: {id: string, name: string}[] = [];
    for (const p of this.payees) {
      if (filterType === null || p.type === filterType) {
        if (p.type !== 'agent' || filterActive === null || p.active === filterActive) {
          payeesRet.push({id: p.id.toString(), name: p.name});
        }
      }
    }
    return payeesRet;
  }

  // getFieldKeys(): string[] {
  //   const fieldKeys: string[] = [];
  //   for (const tf of this.transactionFields.formFields) {
  //     fieldKeys.push(tf.key);
  //   }
  //   return fieldKeys;
  // }

  // fromJSON(fieldKey: string, fieldValue: any) {
  //   for (const tf of this.transactionFields.formFields) {
  //     if (tf.key === fieldKey) {
  //       return tf.fromJSON(fieldValue);
  //     }
  //   }
  //   return fieldValue;
  // }

}
