import { FormFieldBase } from './form-field-base';
import { ReflectiveInjector, Injector } from '@angular/core';
import { TransactionsPayeesService } from '../transactions/transactions-payees.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';

export class AgentField extends FormFieldBase<number> {
  controlType = 'agent';
  // options: {key: number, value: string}[] = [];
  // payeeService: TransactionsPayeesService;

  // constructor(options: {} = {}) {
  //   super(options);
  //   this.options = options['options'] || [];
  // }

  constructor() {
    super();

    // let resolvedProviders = ReflectiveInjector.resolve([TransactionsPayeesService]);
    // let childInjector = ReflectiveInjector.fromResolvedProviders(resolvedProviders, this.injector);
    // this.payeeService = childInjector.get(TransactionsPayeesService);

    this.fieldType = 'Dropdown';
  }

  getOptions(): {key: string, value: string}[] {
    return TransactionsPayeesService.instance.getDropdownOptions('agent');
  }

  initField(options: {} = {}) {
    super.initField(options);
    // this.options = options['options'] || [];
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    // console.log('valueObj:' + valueObj)
    return TransactionsPayeesService.instance.getNameById(valueObj);
  }

}
