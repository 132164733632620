import { Component, OnInit } from '@angular/core';

import * as fromApp from './store/app.reducer';
import { Store } from '@ngrx/store';
import { TransactionsPayeesService } from './transactions/transactions-payees.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ren';

  constructor (private store: Store<fromApp.AppState>,
               private transactionsPayeeService: TransactionsPayeesService) {}

  ngOnInit() {
  }
}
