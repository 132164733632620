import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as transactionsActions from './transactions.actions';
import { Transaction } from '../transaction';
import { TransactionCommission } from '../transaction-commission';
import * as cloneDeep from 'lodash/cloneDeep';

import * as fromApp from '../../store/app.reducer';

// Assoc Array of Transactions:
interface TransactionsById {
  [id: string]: Transaction;
}

// Linked List of Transactions (For next/prev in transaction view page)
interface TransactionLinkedListItem {
  nextId: string;
  prevId: string;
}
interface TransactionLinkedList {
  [id: string]: TransactionLinkedListItem;
}
export interface PayeeItem {
  id: number;
  active: boolean;
  companyId: number;
  teamId: number;
  type: string;
  name: string;
  companyDollar: boolean;
}
interface PayeesById {
  [id: number]: PayeeItem;
}

// NGRX Store State:
export interface State {
  lastIndex: number;
  transactionsById: TransactionsById,
  transactionsLinkedList: TransactionLinkedList,
  payeesById: PayeesById,
  listProps: {
    curPage: number,
    pageSize: number,
    sorts: {dir: string, prop: string}[],
  };
}

const initialState: State = {
  lastIndex: 10,
  transactionsById: {},
  transactionsLinkedList: {},
  payeesById: {},
  listProps: {
    curPage: 1,
    pageSize: 10,
    sorts: [{dir: 'desc', prop: 'fieldVals.closingDate'}],
  },
};

export function transactionReducer(transactionState: State | undefined, transactionAction: Action) {
  return createReducer(
    initialState,
    on(transactionsActions.updateTransaction, (state, action) => {
      const updatedTransaction = cloneDeep(action.transaction);

      updatedTransaction.recalculateCommission();
      const newTransactionsById = cloneDeep(state.transactionsById);
      newTransactionsById[updatedTransaction.id] = updatedTransaction;

      // console.log('updateTransaction:')
      // console.log(action.transaction);
      // console.log(newTransactionsById[updatedTransaction.id]);

      return {
        ...state,
        transactionsById: newTransactionsById,
     };
    }),
    // on(transactionsActions.deleteTransaction, (state, action) =>
    // {
    //   const newTransactionsById = cloneDeep(state.transactionsById);
    //   newTransactionsById.delete(state.transactions[action.index].id);
    //   return {
    //     ...state,
    //     transactions: state.transactions.filter((transaction, index) => index !== action.index),
    //     transactionsById: newTransactionsById,
    //   }
    // }),
    on(transactionsActions.setTransactions, (state, action) =>
      {
        const newTransactionsById: TransactionsById = {};
        for (const tr of action.transactions) {
          newTransactionsById[tr.id] = tr;
        }
        return {
        ...state,
        transactionsById: newTransactionsById,
      };
    }),
    on(transactionsActions.setPayees, (state, action) =>
      {
        const newPayeesById = {};
        for (const p of action.payees) {
          // console.log(p)
          newPayeesById[p.id] = p;
        }
        return {
        ...state,
        payeesById: newPayeesById,
      };
    }),
    on(transactionsActions.setTransactionSortOrder, (state, action) =>
      {
        const newTransactionsLinkedList: TransactionLinkedList = {};
        let prevId = null;
        for (const id of action.sortOrder) {
          if (prevId != null) {
            newTransactionsLinkedList[prevId].nextId = id;
          }
          const newTransactiontLinkedListItem = {
            nextId: null,
            prevId: prevId,
          }
          newTransactionsLinkedList[id] = newTransactiontLinkedListItem;
          prevId = id;
        }
        return {
        ...state,
        transactionsLinkedList: newTransactionsLinkedList,
      };
    }),
    on(transactionsActions.setCurPage, (state, action) =>
      ({
        ...state,
        listProps: {
          ...state.listProps,
          curPage: action.curPage
        }
      })),
    on(transactionsActions.setPageSize, (state, action) => {
      const newCurPage = (state.listProps.curPage * state.listProps.pageSize) / action.pageSize;
      return {
        ...state,
        listProps: {
          ...state.listProps,
          curPage: newCurPage,
          pageSize: action.pageSize
        }
      }
    }),
    on(transactionsActions.setSorts, (state, action) =>
      ({
        ...state,
        listProps: {
          ...state.listProps,
          sorts: action.sorts
        }
      })),
  )(transactionState, transactionAction);
}
