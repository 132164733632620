import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams } from '@angular/common/http';
import { exhaustMap, take, map } from 'rxjs/operators';
import * as fromApp from '../store/app.reducer';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private store: Store<fromApp.AppState>) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select('auth').pipe(
      take(1),
      map(authState => {return authState.user;}),
      exhaustMap(user => {
        // console.log('AuthInteceptorService');
        // console.log(user);
        if (!user) {
          // console.log('No HDR1:' + req.url)
          return next.handle(req);
        }
        if (false) {
          console.log('No HDR1:' + req.url)
          return next.handle(req);
        } else {
          let sessionToken = user.cognitoUser.signInUserSession.idToken.jwtToken;
          // tslint:disable-next-line: max-line-length
          // sessionToken = 'eyJraWQiOiJFUDg5MTkwNG1hQVlaNmloSDl0MXhsYXJoMTZMNStNb09jVlFhZ3lWVnNzPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmZDdiZDMzZi02NjM1LTQ4NzAtYWFlYy0wYjU2MzMxMDlhZDciLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfZWlhNHM0VDNiIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImZkN2JkMzNmLTY2MzUtNDg3MC1hYWVjLTBiNTYzMzEwOWFkNyIsImF1ZCI6IjN1NGs0dGhkZWVjOGhpZDY0aXA0a21pbTdpIiwiZXZlbnRfaWQiOiJlZDBkYjNjNi1hMDU0LTQzZmYtYjM1My04YTgwMzBiYzFiOGYiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTU4NDgxNzYyOCwicGhvbmVfbnVtYmVyIjoiKzE5OTk5OTk5OTk5IiwiZXhwIjoxNTg1MjYxNzI2LCJpYXQiOjE1ODUyNTgxMjYsImVtYWlsIjoiY2hpbmF0dGlAZ21haWwuY29tIn0.iRY0WgnsHyeFpDXb4rL6HcgTeDQmGktTBxGvKb1xI9WAQ19ymS31uMnI4C6LoWX2lHB8icmA0cqbhtgxzH-vvDoWqP6Pi5PrWI63dvxb6JCwiex8sOj8uTMaw5B4GBNYhGqIgt61IooA3Qhqd46Yj_LVkz9r6-lmlXp42s9jyb0KntvpAb7QceC7D1HsRFnvnoEP3fEZCCMXm_18ur_PdzmY_BSUGBbtjusYCSuHXbCdfpPckshjBsak89hZArsaAWpzBbTW5EMNGLMrySCYko6EzpyhUByAe3J9WeEUQvsAJhBFx2PZ_B22DSlICaWJimvUEyPO-gnYG2nroxRfpg';

          const newReq = req.clone({
            withCredentials : false,
            setHeaders: {
              Authorization: `Bearer ${sessionToken}`
                          }
          });
          // console.log('URL: ' + newReq.url);
          // console.log('Token: ' + sessionToken);
          return next.handle(newReq);
        }
      })
    );
  }
}
