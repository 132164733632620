import { FormFieldBase } from './form-field-base';

export class DropdownField extends FormFieldBase<string> {
  controlType = 'dropdown';
  options: {key: string, value: string}[] = [];

  // constructor(options: {} = {}) {
  //   super(options);
  //   this.options = options['options'] || [];
  // }

  constructor() {
    super();
    this.fieldType = 'Dropdown';
  }

  getOptions(): {key: string, value: string}[] {
    return this.options;
  }

  initField(options: {} = {}) {
    super.initField(options);
    this.options = options['options'] || [];
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    for (const opt of this.options) {
      if (opt.key === valueObj) {
        return opt.value;
      }
    }
    return valueObj;
  }

}
