import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, PayeeItem } from './transactions.reducer';
import { Transaction } from '../transaction';

export const getTransactionsState = createFeatureSelector<State>('transactions');

export const getTransactions = createSelector(
  getTransactionsState,
  (state: State) => {
    const transactions: Transaction[] = [];
    for (const id of Object.keys(state.transactionsById)) {
      transactions.push(state.transactionsById[id]);
    }
    return transactions;
  }
);

export const getTransactionsById = createSelector(
  getTransactionsState,
  (state: State) => state.transactionsById
);

export const getTransactionByID = createSelector(
  getTransactionsState,
  (state, props) => {
    return state.transactionsById[props.id];
  }
);

export const getListProps = createSelector(
  getTransactionsState,
  (state: State) => state.listProps
);

export const getTransactionsLinkedList = createSelector(
  getTransactionsState,
  (state: State) => state.transactionsLinkedList
);

export const getTransactionsLinkedListById = createSelector(
  getTransactionsState,
  (state, props) => {
    if (props.id === null) {
      return null;
    }
    return state.transactionsLinkedList[props.id];
  }
);

export const getPayees = createSelector(
  getTransactionsState,
  (state: State) => {
    const payees: PayeeItem[] = [];
    for (const id of Object.keys(state.payeesById)) {
      payees.push(state.payeesById[id]);
    }
    // return payees;
    return payees.sort((a, b) => (a.name > b.name) ? 1 : -1)
  }
);

export const getPayeesById = createSelector(
  getTransactionsState,
  (state: State) => state.payeesById
);

export const getPayeeByID = createSelector(
  getTransactionsState,
  (state, props) => {
    return state.payeesById[props.id];
  }
);
