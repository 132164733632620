import { FormFieldBase } from './form-field-base';

export class AddressField extends FormFieldBase<{addr: string, city: string, state: string, zip: string}> {
  controlType = 'address';

  constructor() {
    super();
    // this.type = options['type'] || '';
    this.value = { addr: null, city: null, state: null, zip: null};
    this.subKeys = ['addr','city','state','zip'];
    this.fieldType = 'Address';
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    return valueObj.addr + '\n' + valueObj.city + ', ' + valueObj.state + ' ' + valueObj.zip;
  }

  sortCompare(valueA, valueB, rowA, rowB, sortDirection) {
    let streetA = '';
    let streetB = '';
    if (valueA['addr'] != null && valueA['addr'] != undefined && valueA['addr'] != '') {
      streetA = valueA['addr'].replace(/^[\d\s-]*\s+/, '');
    }
    if (valueB['addr'] != null && valueB['addr'] != undefined && valueB['addr'] != '') {
      streetB = valueB['addr'].replace(/^[\d\s-]*\s+/, '');
    }
    if (streetA === streetB) {
      const idAgtidB = +rowA.id > +rowB.id;
      const ret: number = ((sortDirection === 'desc') ? 1 : -1) * (idAgtidB ? 1 : -1);
      // console.log('valA=' + valueA + ' valueB=' + valueB + ' idA=' + rowA.id + ' idB=' + rowB.id + ' sd=' + sortDirection + ' ret:' + ret)
      return ret;
    } else if (streetA < streetB) {
      return -1;
    } else {
      return 1;
    }
  }

}

