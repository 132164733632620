import { FormFieldBase } from './form-field-base';

export class DateField extends FormFieldBase<Date> {
  controlType = 'date';

  constructor() {
    super();
    // this.type = options['type'] || '';
    //this.value = { firstName: null, lastName: null};
    this.fieldType = 'Date';
  }

  getViewString(valueObj: any): string {
    // console.log(valueObj);
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    if (valueObj instanceof Date) {
      return this.datePipe.transform(valueObj, 'MM/dd/yy');
    }
    return valueObj;
  }

  getFormValue(valueObj: any): any {
    return this.datePipe.transform(valueObj, 'yyyy-MM-dd');
  }

  extractFormValue(field: FormFieldBase<any>, formValues: any): any {
    // console.log(formValues[field.key]);
    // console.log(new Date(formValues[field.key]));
    // console.log(new Date(this.datepipe.transform(formValues[field.key], 'MM/dd/yyyy')));
    if (formValues[field.key]) {
      let newDate = null
      try {
        newDate = new Date(this.datePipe.transform(formValues[field.key], 'yyyy-MM-dd'));
      }
      catch (err) {
        newDate = null;
      }
      return newDate;
    } else {
      return formValues[field.key];
    }
  }

  toJSON(valObj: Date): any {
    return this.datePipe.transform(valObj, 'yyyy-MM-dd');
  }

  fromJSON(jsonVal: any): any {
    // console.log(jsonVal)
    if (jsonVal === undefined || jsonVal == null) {
      return undefined;
    } else {
      let newDate = null;
      const dates = jsonVal.split('-');
      // console.log('dates: ' + dates.join(','));
      if (dates.length === 3) {
        newDate = new Date(dates[0],+dates[1]-1,dates[2]);
      }
      // console.log('TR: json:' + jsonVal + ' -> ' + newDate);
      return newDate;
    }
  }

}
