import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Router } from '@angular/router';

import { tap, switchMap, map } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import * as ConfigActions from '../../config/store/config.actions';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable()
export class AuthEffects {

  constructor(private http: HttpClient, private actions$: Actions, private router: Router, private amplifyService: AmplifyService) {
  }

  authLogin = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLogin),
      map(action => {
        if (action.redirect) {
          let newUrl = '/';
          if (action.url) {
            newUrl = action.url;
          }
          this.router.navigate([newUrl]);
        }
        // console.log('sending refreshAll action');
        return ConfigActions.refreshAll();
        // return ConfigActions.nullAction();
      })
    )//, { dispatch: false } // <- this effect doesn't dispatch a new effect
  );

  authLogout = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.userLogout),
      tap(
        action => {
          // console.log('LOGOUT')
          this.amplifyService.auth().signOut();
          this.router.navigate(['/auth']);
        }
      )
    ), { dispatch: false } // <- this effect doesn't dispatch a new effect
  );

}
