import { createAction, props } from '@ngrx/store';
import { FormFieldBase } from 'src/app/transaction-fields/form-field-base';
import { TransactionFields } from 'src/app/transaction-fields/transaction-fields';

export const refreshAll = createAction('[config] RefreshAll');
export const nullAction = createAction('[config] Null');

export const loadingDone = createAction('[config] Loading Done',
  props<{ loadingType: string }>());

export const fetchTransactionFields = createAction('[config] Fetch Transaction Fields',
  props<{ refreshAll: boolean }>());

export const addTransactionField = createAction('[config] Add Transaction Field',
  props<{ transactionField: FormFieldBase<any> }>());
export const setTransactionFields = createAction('[config] Update Transaction Fields',
  props<{ transactionFields: TransactionFields }>());
export const reverseTransactionFields = createAction('[config] Reverse Transaction Fields');
