import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as companyActions from './company.actions';
import * as ConfigActions from '../../config/store/config.actions';
import { switchMap, map, withLatestFrom, tap, flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fromApp from '../../store/app.reducer';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../environments/environment';

@Injectable()
export class CompanyEffects {

  fetchTransactions = createEffect(() =>
    this.actions$.pipe(
    // tap(action => {console.log(action)}),
    ofType(companyActions.fetchStats),
    switchMap(fetchAction => {
      // console.log('Getting transactions');
      const getret = this.http.get<any>(
        environment.awsApiUrlBase + '/stats',
      );
      // console.log('got:');
      // console.log(getret);
      return getret;
    }),
    flatMap(stats => {
      // console.log(stats)
      // this.store.dispatch(ConfigActions.fetchTransactionFields());
      // return [
      //   companyActions.setStats({stats: stats}),
      //   ConfigActions.fetchTransactionFields()
      // ];
      const nextActions = []
      nextActions.push(companyActions.setStats({stats: stats}));
      nextActions.push(ConfigActions.loadingDone({loadingType: 'company'}));
      return nextActions;
    }),
    )
  );

  constructor (private actions$: Actions, private http: HttpClient, private store: Store<fromApp.AppState>) {}
}
