import { TransactionsPayeesService } from './transactions-payees.service';

export enum TransactionCommissionLineOutputType {
  LINE_ITEM = 0,  // this output goes to another commission line item
  PAYMENT = 1,    // this output is a payment
}

export class TransactionCommissionLine {
  public description: string;
  public isFixedAmount: boolean;
  public amount: number;
  public inputNode: number;
  public inputNodeFromPrimary: boolean;
  public numberOfOutputs: number;
  public primaryOutputType: TransactionCommissionLineOutputType;
  // public primaryOutputPayee: string;
  public primaryOutputPayeeId: string;
  public primaryOutputNodeIndex: number;
  public secondaryOutputType: TransactionCommissionLineOutputType;
  public secondaryOutputNodeIndex: number;
  // public secondaryOutputPayee: string;
  public secondaryOutputPayeeId: string;

  // Calculated Amounts:
  public inputAmount: number = null;
  public inputStr: string = null;
  public primaryOutputAmount: number = null;
  // public primaryOutputStr: string = null;
  public secondaryOutputAmount: number = null;
  // public secondaryOutputStr: string = null;

  constructor() {}

  getPrimaryAmount(inputAmount: number): number {
    if (this.numberOfOutputs === 1) {
      if (this.isFixedAmount) {
        return +this.amount.toFixed(2);
      } else {
        return +((inputAmount * this.amount) / 100.0).toFixed(2);
      }
    } else {
      return +(inputAmount - this.getSecondaryAmount(inputAmount)).toFixed(2);
    }
  }

  getSecondaryAmount(inputAmount: number): number {
    // let sa_num: number = inputAmount - this.getPrimaryAmount(inputAmount);
    // console.log('SA: in=' + inputAmount + ' PA=' + this.getPrimaryAmount(inputAmount) + ' SA=' + sa_num);
    // return +(inputAmount - this.getPrimaryAmount(inputAmount)).toFixed(2);
    if (this.numberOfOutputs === 1) {
      return 0;
    } else if (this.isFixedAmount) {
      return +this.amount.toFixed(2);
    } else {
      return +((inputAmount * this.amount) / 100.0).toFixed(2);
    }
  }

  clearCache() {
    this.inputAmount = null;
    this.inputStr = null;
    this.primaryOutputAmount = null;
    // this.primaryOutputStr = null;
    this.secondaryOutputAmount = null;
    // this.secondaryOutputStr = null;
    // console.log('clearCache() called')
  }

  updateCache(inputAmount: number, inputStr: string, lineItems: TransactionCommissionLine[]) {
    if (this.primaryOutputAmount !== null) {
      return;
    }
    this.inputAmount = inputAmount;
    this.inputStr = inputStr;
    this.primaryOutputAmount = this.getPrimaryAmount(inputAmount);
    this.secondaryOutputAmount = this.getSecondaryAmount(inputAmount);
    // console.log('RECALC: inp=' + inputAmount + ' pri=' + this.primaryOutputAmount + ' sec=' + this.secondaryOutputAmount);
    // console.log(this);
    if (this.primaryOutputType === TransactionCommissionLineOutputType.LINE_ITEM) {
      // console.log('RECALC: update pri[' + this.primaryOutputNodeIndex + ']');
      lineItems[this.primaryOutputNodeIndex].updateCache(this.primaryOutputAmount,this.description,lineItems)
      // this.primaryOutputStr = lineItems[this.primaryOutputNodeIndex].description;
    } else {
      // this.primaryOutputStr = TransactionsPayeesService.instance.getNameById(this.primaryOutputPayeeId);
      // console.log('LINE(P): id=' + this.primaryOutputPayeeId + ' str=' + this.primaryOutputStr)
    }
    if (this.numberOfOutputs > 1) {
      if (this.secondaryOutputType === TransactionCommissionLineOutputType.LINE_ITEM) {
        // console.log('RECALC: update sec[' + this.secondaryOutputNodeIndex + ']');
        lineItems[this.secondaryOutputNodeIndex].updateCache(this.secondaryOutputAmount,this.description,lineItems)
        // this.secondaryOutputStr = lineItems[this.secondaryOutputNodeIndex].description;
      } else {
        // this.secondaryOutputStr = TransactionsPayeesService.instance.getNameById(this.secondaryOutputPayeeId);
      }
    }
  }

  toStr(): string {
    let str = '';
    if (this.isFixedAmount) {
      str += '$' + this.amount;
    } else {
      str += this.amount + '%';
    }
    str += ' pri:';
    if (this.primaryOutputAmount !== null) {
      str += '{' + this.primaryOutputAmount + '}';
    }
    if (this.primaryOutputType === TransactionCommissionLineOutputType.PAYMENT) {
      str += 'PAY:' + TransactionsPayeesService.instance.getNameById(this.primaryOutputPayeeId);
    } else {
      str += 'LINE[' + this.primaryOutputNodeIndex + ']';
    }
    if (this.numberOfOutputs > 1) {
      str += ' sec:';
      if (this.secondaryOutputAmount !== null) {
        str += '{' + this.secondaryOutputAmount + '}';
      }
      if (this.secondaryOutputType === TransactionCommissionLineOutputType.PAYMENT) {
        str += 'PAY:' + TransactionsPayeesService.instance.getNameById(this.secondaryOutputPayeeId);
      } else {
        str += 'LINE[' + this.secondaryOutputNodeIndex + ']';
      }
    }
    return str;
  }

  toJSON() {
    return {
      description: this.description,
      isFixedAmount: this.isFixedAmount,
      amount: this.amount,
      inputNode: this.inputNode,
      inputNodeFromPrimary: this.inputNodeFromPrimary,
      numberOfOutputs: this.numberOfOutputs,
      primaryOutputType: (this.primaryOutputType === TransactionCommissionLineOutputType.LINE_ITEM) ? 'line_item' : 'payment',
      primaryOutputPayeeId: this.primaryOutputPayeeId,
      primaryOutputNodeIndex: this.primaryOutputNodeIndex,
      secondaryOutputType: (this.secondaryOutputType === TransactionCommissionLineOutputType.LINE_ITEM) ? 'line_item' : 'payment',
      secondaryOutputNodeIndex: this.secondaryOutputNodeIndex,
      secondaryOutputPayeeId: this.secondaryOutputPayeeId,
    }
  }

  fromJSON(jsonData) {
    this.description = jsonData.description;
    this.isFixedAmount = jsonData.isFixedAmount;
    this.amount = +jsonData.amount;
    this.inputNode = jsonData.inputNode;
    this.inputNodeFromPrimary = jsonData.inputNodeFromPrimary;
    this.numberOfOutputs = jsonData.numberOfOutputs;
    if (jsonData.primaryOutputType && jsonData.primaryOutputType.toUpperCase() === 'LINE_ITEM') {
      this.primaryOutputType = TransactionCommissionLineOutputType.LINE_ITEM;
    } else if (jsonData.primaryOutputType && jsonData.primaryOutputType.toUpperCase() === 'PAYMENT') {
      this.primaryOutputType = TransactionCommissionLineOutputType.PAYMENT;
    } else{
      this.primaryOutputType = jsonData.primaryOutputType;
    }
    this.primaryOutputPayeeId = jsonData.primaryOutputPayeeId;
    this.primaryOutputNodeIndex = jsonData.primaryOutputNodeIndex;
    if (jsonData.secondaryOutputType && jsonData.secondaryOutputType.toUpperCase() === 'LINE_ITEM') {
      this.secondaryOutputType = TransactionCommissionLineOutputType.LINE_ITEM;
    } else if (jsonData.secondaryOutputType && jsonData.secondaryOutputType.toUpperCase() === 'PAYMENT') {
      this.secondaryOutputType = TransactionCommissionLineOutputType.PAYMENT;
    } else{
      this.secondaryOutputType = jsonData.secondaryOutputType;
    }
    this.secondaryOutputNodeIndex = jsonData.secondaryOutputNodeIndex;
    this.secondaryOutputPayeeId = jsonData.secondaryOutputPayeeId;
    // console.log(jsonData);
    // console.log(this);
  }

}
