import { FormFieldBase } from './form-field-base';

export class CheckboxField extends FormFieldBase<boolean> {
  controlType = 'checkbox';
  type: string;

  constructor() {
    super();
    this.fieldType = 'Checkbox';
  }

  getViewString(valueObj: any): string {
    if (valueObj === null || valueObj === undefined) {
      return '';
    }
    return valueObj ? 'Yes' : 'No';
  }

}
