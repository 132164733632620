import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import { getAuthState } from '../auth/store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (private router: Router, private store: Store<fromApp.AppState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.store.select(getAuthState).pipe(
        take(1),
        map(authState => {
          // console.log('AUTH: router:' + this.router.url + ' state:' + state.url)
          // console.log(authState);
          if (authState.user === null) {
            // console.log('AUTH FAIL 1');
            return this.router.createUrlTree(['/auth'], {
              queryParams: {url: state.url},
            });
          } else {
            // console.log('AUTH PASS');
            return true;
          }
        }
        ));

      // return this.store.select('auth').pipe(
      //   take(1),
      //   map(authState => {return authState.user;}),
      //   map(user => {
      //   if (!!user) {
      //     return true;
      //   } else{
      //     return this.router.createUrlTree(['/auth']);
      //   }
      // }));
  }
}
