import * as fromConfig from '../config/store/config.reducer';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromTransactions from '../transactions/store/transactions.reducer';
import * as fromActivities from '../activities/store/activities.reducer';
import * as fromCompany from '../company/store/company.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  config: fromConfig.State;
  auth: fromAuth.State;
  transactions: fromTransactions.State;
  company: fromCompany.State;
  activities: fromActivities.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  config: fromConfig.configReducer,
  auth: fromAuth.authReducer,
  transactions: fromTransactions.transactionReducer,
  company: fromCompany.companyReducer,
  activities: fromActivities.activitiesReducer,
};


