import { createAction, props } from '@ngrx/store';
import { Transaction } from '../transaction';
import { TransactionFields } from 'src/app/transaction-fields/transaction-fields';

// REST API / DB functions
export const fetchTransactions = createAction('[transaction] Fetch Transactions');
export const storeTransaction = createAction('[transaction] Store Transaction', props<{ transaction: Transaction }>());
export const addTransaction = createAction('[transaction] Add Transaction', props<{ transaction: Transaction }>());

export const updateTransaction = createAction('[transaction] Update Transaction', props<{ transaction: Transaction }>());
export const deleteTransaction = createAction('[transaction] Delete Transaction', props<{ index: number }>());
export const setTransactions = createAction('[transaction] Set Transactions', props<{ transactions: Transaction[] }>());

export const setCurPage = createAction('[transaction] Set curPage', props<{ curPage: number }>());
export const setPageSize = createAction('[transaction] Set pageSize', props<{ pageSize: number }>());
export const setSorts = createAction('[transaction] Set sorts', props<{ sorts: [{dir: 'desc', prop: 'fieldVals.contractDate'}] }>());
export const setTransactionSortOrder = createAction('[transaction] Set Transaction Sort Order', props<{ sortOrder: string[] }>());

export const fetchPayees = createAction('[transaction] Fetch Payees');
export const setPayees = createAction('[transaction] Set Payees', props<{ payees: any }>());
