import { DatePipe } from '@angular/common';

import { TransactionCommission } from './transaction-commission';
import { FormFieldBase } from '../transaction-fields/form-field-base';
import { TransactionCommissionLine, TransactionCommissionLineOutputType } from './transaction-commission-line';
import { FormControlService } from '../transaction-fields/form-control.service';
import { TransactionFieldsService } from '../transaction-fields/transaction-fields.service';
// import { AppModule } from '../app.module';
import { TransactionsModule } from './transactions.module';
import { TransactionFields } from '../transaction-fields/transaction-fields';

interface TransactionFieldValues {
  [key: string]: string | boolean | number | Date | TransactionFieldValues;
}

export class Transaction {
  public datepipe: DatePipe;

  public id: string;
  public commission: TransactionCommission;

  public fieldVals: any = {};

  // private transactionFieldsService: TransactionFieldsService;

  constructor () {
    // this.recalculateCommission();
    this.fieldVals['clientName'] = {firstName: null, lastName: null};
    this.fieldVals['address'] = {addr: null, city: null, state: null, zip: null};
    this.datepipe = new DatePipe('en-US');

    // this.transactionFieldsService = TransactionsModule.injector.get(TransactionFieldsService);

    this.commission = new TransactionCommission();

    let newLine = new TransactionCommissionLine();
    newLine.description = 'Transaction GCI';
    newLine.isFixedAmount = false;
    newLine.amount = 5.0;
    newLine.inputStr = 'Sale Price';
    newLine.inputNode = -1;
    newLine.inputNodeFromPrimary = true;
    newLine.numberOfOutputs = 1;
    newLine.primaryOutputType =  TransactionCommissionLineOutputType.PAYMENT;
    newLine.primaryOutputPayeeId = '0';
    this.commission.addNewLine(newLine, -1, true);
  }

  getFieldValue(field: FormFieldBase<any>): string {
    // console.log(field)
    // console.log(this.fieldVals[field.key])
    return field.getViewString(this.fieldVals[field.key]);
  }

  recalculateCommission() {
    if (this.commission) {
      // console.log('RECALC_TRANS:' + this.id)
      for (let i = 0 ; i < this.commission.lineItems.length ; i++) {
        this.commission.lineItems[i].clearCache();
      }
      this.commission.lineItems[0].updateCache(this.fieldVals['price'],'Sale Price',this.commission.lineItems);
      // console.log('POST_RECALC:')
      // console.log(this.commission)
    }
  }

  get GCI(): number {
    if (this.commission === null) {
      return -1;
    }
    return this.commission.getGCI(this.fieldVals['price']);
  }

  getGCI(): number {
    if (this.commission === null) {
      return -1;
    }
    return this.commission.getGCI(this.fieldVals['price']);
  }

  toJSON(transactionFields: TransactionFields) {
    let jsonOut = {};
    jsonOut['id'] = this.id;
    jsonOut['commission'] = this.commission.toJSON();
    jsonOut['fieldVals'] = {};
    for (const field of transactionFields.formFields) {
      jsonOut['fieldVals'][field.key] = field.toJSON(this.fieldVals[field.key]);
    }
    return jsonOut;
  }

  fromJSON(transactionFields: TransactionFields, jsonData) {
    // console.log(this.fieldVals);
    // console.log(jsonData);
    // console.log(transactionFields)

    this.id = jsonData.id;
    this.commission.fromJSON(jsonData.commission);

    for (const field of transactionFields.formFields) {
      // console.log(field);
      this.fieldVals[field.key] = field.fromJSON(jsonData.fieldVals[field.key]);
    }
    // console.log(this.fieldVals)

    this.recalculateCommission();
    // console.log(this)
  }

}

